import { createContext } from 'react'
import { CartState, initialCartState } from './state'

export interface CartContextInterface extends CartState {
	fetchOrCreateCart: (orderType?: string) => Promise<void>
	setOrderGroupId: (orderGroupId: number) => void
}

const stub = (): never => {
	throw new Error('You forgot to wrap your component in <CartProvider>.')
}

const initialContext = {
	...initialCartState,
	fetchOrCreateCart: stub,
	setOrderGroupId: stub,
}

const CartContext = createContext<CartContextInterface>(initialContext)

export default CartContext
