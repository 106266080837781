import React from 'react'

export const Maintenance = () => {
	return (
		<>
			<div className="bg-white fixed top-0 right-0 bottom-0 left-0 text-center z-10">
				<div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full">
					<div className="text-xl text-red mb-8">メンテナンス中</div>
					<img className="w-48 mx-auto mb-8" src="/logo2.svg" alt="logo" />
					<div className="mb-8 px-4">
						いつもナガタクをご利用いただき
						<br />
						ありがとうございます。
						<br />
						<br />
						現在ナガタクはメンテンス中です。
						<br />
						大変ご不便をおかけいたしますが、
						<br />
						今しばらくお待ちください。
					</div>
				</div>
			</div>
		</>
	)
}
