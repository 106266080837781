import { AuthState, FirebaseUser } from './state'
import { User } from '../../types/user'

type Action =
	| { type: 'LOGIN_STARTED' }
	| { type: 'LOGIN_FINISHED' }
	| { type: 'SIGNIN_STARTED' }
	| { type: 'SIGNIN_FINISHED'; currentFirebaseUser?: FirebaseUser }
	| { type: 'GET_FIREBASE_USER_STARTED' }
	| { type: 'GET_FIREBASE_USER_FINISHED'; currentFirebaseUser?: FirebaseUser }
	| { type: 'FIND_USER_STARTED' }
	| { type: 'FIND_USER_FINISHED'; currentUser?: User }
	| { type: 'LOGOUT' }
	| { type: 'ERROR'; error?: any }

/**
 * Handles how that state changes in the `useAuth` hook.
 */
export const reducer = (state: AuthState, action: Action): AuthState => {
	switch (action.type) {
		case 'LOGIN_STARTED':
			return {
				...state,
				isLoadingAuth: true,
				error: undefined,
			}
		case 'LOGIN_FINISHED':
			return {
				...state,
				isLoadingAuth: false,
				error: undefined,
			}
		case 'SIGNIN_STARTED':
			return {
				...state,
				error: undefined,
			}
		case 'SIGNIN_FINISHED':
			return {
				...state,
				currentFirebaseUser: action.currentFirebaseUser,
				isLoadingAuth: false,
			}
		case 'GET_FIREBASE_USER_STARTED':
			return {
				...state,
				isLoadingAuth: true,
			}
		case 'GET_FIREBASE_USER_FINISHED':
			return {
				...state,
				currentFirebaseUser: action.currentFirebaseUser,
				isLoadingAuth: false,
			}
		case 'FIND_USER_STARTED':
			return {
				...state,
				isLoadingAuth: true,
			}
		case 'FIND_USER_FINISHED':
			return {
				...state,
				currentUser: action.currentUser,
				isAuthenticated: !!action.currentUser,
				isLoadingAuth: false,
			}
		case 'LOGOUT':
			return {
				...state,
				isAuthenticated: false,
				currentFirebaseUser: undefined,
				currentUser: undefined,
			}
		case 'ERROR':
			return {
				...state,
				isLoadingAuth: false,
				error: action.error,
			}
	}
}
