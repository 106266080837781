import { createContext } from 'react'
import { AuthState, initialAuthState } from './state'

export interface AuthContextInterface extends AuthState {
	login: (email: string, password: string) => Promise<void>
	signInWithGoogle: () => Promise<void>
	signInWithEmailAndPassword: (email: string, password: string) => Promise<void>
	loginWithGoogle: () => Promise<void>
	loginWithEmailAndPassword: (email: string, password: string) => Promise<void>
	logout: () => Promise<void>
	findOrCreateUser: () => Promise<void>
	fetchCurrentUser: () => Promise<void>
	updateEmail: (email: string, password: string, newEmail: string) => Promise<void>
	updatePassword: (email: string, password: string, newPassword: string) => Promise<void>
	resetPassword: (email: string) => Promise<void>
	sendEmailVerification: () => Promise<void>
}

const stub = (): never => {
	throw new Error('You forgot to wrap your component in <AuthProvider>.')
}

const initialContext = {
	...initialAuthState,
	login: stub,
	signInWithGoogle: stub,
	signInWithEmailAndPassword: stub,
	loginWithGoogle: stub,
	loginWithEmailAndPassword: stub,
	logout: stub,
	findOrCreateUser: stub,
	fetchCurrentUser: stub,
	updateEmail: stub,
	updatePassword: stub,
	resetPassword: stub,
	sendEmailVerification: stub,
}

const AuthContext = createContext<AuthContextInterface>(initialContext)

export default AuthContext
