import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FaUserAlt } from 'react-icons/fa'
import { useAuth } from '../context/auth'
import { useCart } from '../context/cart'
import { Divider } from '../components/Divider'

export const Header = () => {
	const router = useRouter()
	const { currentFirebaseUser } = useAuth()
	const { cart } = useCart()

	const [isDrawerOpen, setIsDrawerOpen] = useState(false)

	useEffect(() => {
		setIsDrawerOpen(false)
	}, [router])

	return (
		<>
			{/* スマホ */}
			<div className="bg-white fixed w-full top-0 right-0 left-0 z-10 h-16 block md:hidden">
				<div className="md:w-[1040px] mx-auto">
					<div className="flex items-center justify-between flex-start px-4 py-2">
						<Link href="/">
							<img className="w-32 cursor-pointer" src="/logo.svg" alt="logo" />
						</Link>
						<Link href="/about">
							<img className="w-32 cursor-pointer" src="/about.svg" alt="about" />
						</Link>
						<button onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
							<img className="w-8" src="/menu.svg" alt="menu" />
						</button>
					</div>
				</div>
			</div>

			{/* PC */}
			<div className="bg-white fixed w-full  top-0 right-0 left-0 z-10 h-16 hidden md:block">
				<div className="md:w-[1040px] mx-auto">
					<div className="flex items-center justify-between px-4 py-2">
						<div className="flex items-center">
							<button onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
								<img className="w-8 mr-8" src="/menu.svg" alt="menu" />
							</button>
							<Link href="/">
								<img className="w-32 cursor-pointer" src="/logo.svg" alt="logo" />
							</Link>
						</div>
						<div className="flex items-center">
							<Link href="/about">
								<img className="w-32 mr-8 cursor-pointer" src="/about.svg" alt="about" />
							</Link>
							{currentFirebaseUser?.isAnonymous ? (
								<>
									<Link href="/signup">
										<button className="rounded-lg border-2 border-orange bg-orange text-white font-bold px-4 py-1.5 mr-1.5">
											会員登録
										</button>
									</Link>
									<Link href="/login">
										<button className="rounded-lg border-2 border-orange text-orange font-bold px-4 py-1.5 mr-8">
											ログイン
										</button>
									</Link>
								</>
							) : (
								<Link href="/mypage">
									<div className="mt-2 mr-4 cursor-pointer">
										<>
											<div className="text-2xl">
												<FaUserAlt className="mx-auto" />
											</div>
											<div className="text-[8px]">マイページ</div>
											{/* <button className="rounded-lg border-2 border-orange hover:bg-orange text-orange hover:text-white font-bold px-4 py-1.5 mr-8 ease-in-out transition-all duration-300">
											マイページ
										</button> */}
										</>
									</div>
								</Link>
							)}
							<Link href="/cart">
								<div className="relative cursor-pointer">
									<img className="w-8" src="/cart_b.svg" alt="cart" />
									<div className="text-white absolute top-0 -right-1 bg-orange rounded-full h-4 w-4">
										<div className="text-xs absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
											{cart ? cart.line_items.length : 0}
										</div>
									</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>

			<aside
				className={`transform top-0 left-0 md:right-0 w-full md:w-[300px] md:shadow-md bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 px-6 py-4 ${
					isDrawerOpen ? 'translate-x-0' : '-translate-x-full'
				}`}
			>
				<div className="flex items-center justify-between">
					<div className="flex md:hidden">
						{currentFirebaseUser?.isAnonymous ? (
							<>
								<Link href="/signup">
									<button className="rounded-lg bg-orange text-white font-bold px-4 py-2 mr-1.5">
										会員登録
									</button>
								</Link>
								<Link href="/login">
									<button className="rounded-lg border-2 text-orange border-orange font-bold px-4 py-2">
										ログイン
									</button>
								</Link>
							</>
						) : (
							<>
								<Link href="/mypage">
									<button className="rounded-lg bg-orange text-white font-bold px-4 py-2 mr-1.5">
										マイページ
									</button>
								</Link>
							</>
						)}
					</div>
					<div />
					<button onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
						<img className="w-10" src="/close.svg" alt="close" />
					</button>
				</div>

				<div className="mt-8">
					<ul className="font-bold">
						{/* <li>
							<button
								className="font-bold flex items-center justify-between w-full"
								onClick={() => setIsOrderOpen(!isOrderOpen)}
							>
								<div>注文する</div>
								{isOrderOpen ? (
									<img className="w-6" src="/minus.svg" alt="minus" />
								) : (
									<img className="w-6" src="/plus.svg" alt="plus" />
								)}
							</button>
							<ul
								className={`px-6 divide-y-2 divide-lightGray transform overflow-hidden ease-in-out transition-all duration-300 ${
									isOrderOpen ? 'h-48' : 'h-0'
								}`}
							>
								<li className="py-4" />
								<li className="py-4">
									<div className="flex items-center justify-between">
										<div>デリバリー</div>
										<img className="w-2.5 mr-1" src="/caret_right.svg" alt="caret_right" />
									</div>
								</li>
								<li className="py-4">
									<div className="flex items-center justify-between">
										<div>テイクアウト</div>
										<img className="w-2.5 mr-1" src="/caret_right.svg" alt="caret_right" />
									</div>
								</li>
								<li className="py-4">
									<div className="flex items-center justify-between">
										<div>買い物代行サービス</div>
										<img className="w-2.5 mr-1" src="/caret_right.svg" alt="caret_right" />
									</div>
								</li>
							</ul>
						</li> */}
						{/* <Divider />
						<li>
							<div className="flex items-center justify-between">
								<div>お得情報</div>
								<img className="w-6" src="/plus.svg" alt="plus" />
							</div>
						</li> */}
						<Divider />
						<li className="cursor-pointer">
							<Link href="/lp">
								<div className="flex items-center justify-between">
									<div>インフォメーション</div>
									<img className="w-3 mr-1 md:hidden" src="/caret_right.svg" alt="caret_right" />
								</div>
							</Link>
						</li>
						<Divider />
						<li className="cursor-pointer">
							<Link href="/about">
								<div className="flex items-center justify-between">
									<div>ナガタクについて</div>
									<img className="w-3 mr-1 md:hidden" src="/caret_right.svg" alt="caret_right" />
								</div>
							</Link>
						</li>
						<Divider />
						<li>
							<a
								href="https://docs.google.com/forms/d/e/1FAIpQLSeqrSIrDKshei4MZTtHVHXMbowdpsWHARjlBVlilWnE9HB3WQ/viewform?usp=sf_link"
								target="_blank"
								rel="noreferrer"
							>
								<div className="flex items-center justify-between">
									<div>お問い合わせ</div>
									<img className="w-3 mr-1 md:hidden" src="/caret_right.svg" alt="caret_right" />
								</div>
							</a>
						</li>
						<Divider />
						<li className="cursor-pointer">
							<Link href="/terms">
								<div className="flex items-center justify-between">
									<div>利用規約</div>
									<img className="w-3 mr-1 md:hidden" src="/caret_right.svg" alt="caret_right" />
								</div>
							</Link>
						</li>
						<Divider />
						<li className="cursor-pointer">
							<Link href="/privacy">
								<div className="flex items-center justify-between">
									<div>プライバシーポリシー</div>
									<img className="w-3 mr-1 md:hidden" src="/caret_right.svg" alt="caret_right" />
								</div>
							</Link>
						</li>
						{/* <Divider />
						<li>
							<Link href="/company">
								<div className="flex items-center justify-between">
									<div>運営会社</div>
									<img className="w-3 mr-1" src="/caret_right.svg" alt="caret_right" />
								</div>
							</Link>
						</li> */}
					</ul>
				</div>

				<img className="w-60 mx-auto mt-16 md:hidden" src="/logo2.svg" alt="logo" />
			</aside>
		</>
	)
}
