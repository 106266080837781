import { CartState } from './state'
import { Cart } from '../../types/cart'

type Action =
	| { type: 'FETCH_CART_STARTED' }
	| { type: 'FETCH_CART_FINISHED'; cart: Cart }
	| { type: 'SET_ORDER_GROUP_ID'; orderGroupId: number }
	| { type: 'ERROR'; error?: any }

/**
 * Handles how that state changes in the `useCart` hook.
 */
export const reducer = (state: CartState, action: Action): CartState => {
	switch (action.type) {
		case 'FETCH_CART_STARTED':
			return {
				...state,
				isLoadingCart: true,
				error: undefined,
			}
		case 'FETCH_CART_FINISHED':
			return {
				...state,
				cart: action.cart,
				isLoadingCart: false,
				error: undefined,
			}
		case 'SET_ORDER_GROUP_ID':
			return {
				...state,
				orderGroupId: action.orderGroupId,
			}
		case 'ERROR':
			return {
				...state,
				isLoadingCart: false,
				error: action.error,
			}
	}
}
