import React from 'react'
import Link from 'next/link'

export const Footer = () => {
	return (
		<>
			<div className="bg-yellow text-center px-4">
				<ul className="divide-y divide-black md:w-[1040px] mx-auto">
					<li className="pt-2 pb-3" />
					<li className="py-3">
						<Link href="/terms">利用規約</Link>
					</li>
					<li className="py-3">
						<Link href="/privacy">プライバシーポリシー</Link>
					</li>
					{/* <li className="py-3">
						<Link href="/company">運営会社概要</Link>
					</li> */}
					<li className="py-3">(C) 2021 ナガタク</li>
				</ul>
			</div>
		</>
	)
}
