import React, { useEffect, useReducer } from 'react'
import firebase from 'firebase/app'
import { axios } from '../../lib/axios-client'
import AuthContext from './context'
import { reducer } from './reducer'
import { initialAuthState } from './state'
import { User } from '../../types/user'

export interface AuthProviderOptions {
	children?: React.ReactNode
	auth: firebase.auth.Auth
	[key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const AuthProvider = (opts: AuthProviderOptions) => {
	const { children, auth } = opts

	const [state, dispatch] = useReducer(reducer, initialAuthState)

	const login = async (email: string, password: string): Promise<void> => {
		dispatch({ type: 'LOGIN_STARTED' })
		try {
			await auth.signInWithEmailAndPassword(email, password)
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const logout = async (): Promise<void> => {
		try {
			await auth.signOut()
			dispatch({ type: 'LOGOUT' })
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const signInWithGoogle = async (): Promise<void> => {
		try {
			const provider = new firebase.auth.GoogleAuthProvider()

			const result = await auth.signInWithPopup(provider)
			if (result.credential) {
				const credential = await auth.currentUser?.linkWithCredential(result.credential)
				if (credential?.user) {
					dispatch({ type: 'SIGNIN_FINISHED', currentFirebaseUser: credential.user })
				}
			}
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const signInWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
		try {
			dispatch({ type: 'SIGNIN_STARTED' })
			await auth.currentUser?.linkWithCredential(
				firebase.auth.EmailAuthProvider.credential(email, password)
			)
			await sendEmailVerification()
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const loginWithGoogle = async (): Promise<void> => {
		try {
			const provider = new firebase.auth.GoogleAuthProvider()
			const credential = await auth.signInWithPopup(provider)
			if (credential?.user) {
				dispatch({ type: 'SIGNIN_FINISHED', currentFirebaseUser: credential.user })
			}
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const loginWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
		try {
			dispatch({ type: 'SIGNIN_STARTED' })
			await auth.signInWithEmailAndPassword(email, password)
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const findOrCreateUser = async () => {
		try {
			dispatch({ type: 'FIND_USER_STARTED' })
			let currentUser: User
			const res = await axios.get(`${process.env.REST_ENDPOINT}/api/v1/users/find`)
			currentUser = res.data

			if (!currentUser) {
				try {
					const res = await axios.post(`${process.env.REST_ENDPOINT}/api/v1/users`)
					currentUser = res.data
				} catch (error) {
					dispatch({ type: 'ERROR', error })
				}
			}

			dispatch({ type: 'FIND_USER_FINISHED', currentUser })
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const fetchCurrentUser = async () => {
		try {
			const res = await axios.get(`${process.env.REST_ENDPOINT}/api/v1/users/find`)
			dispatch({ type: 'FIND_USER_FINISHED', currentUser: res.data })
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const updateEmail = async (email: string, password: string, newEmail: string) => {
		try {
			await auth.signInWithEmailAndPassword(email, password)
			if (state.currentFirebaseUser) {
				await state.currentFirebaseUser.updateEmail(newEmail)
				await sendEmailVerification()
			}
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const updatePassword = async (email: string, password: string, newPassword: string) => {
		try {
			await auth.signInWithEmailAndPassword(email, password)
			if (state.currentFirebaseUser) {
				await state.currentFirebaseUser.updatePassword(newPassword)
			}
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const resetPassword = async (email: string) => {
		try {
			await auth.sendPasswordResetEmail(email)
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	const sendEmailVerification = async (url?: string) => {
		try {
			const actionCodeSettings = url ? { url } : null
			await state.currentFirebaseUser?.sendEmailVerification(actionCodeSettings)
		} catch (error) {
			dispatch({ type: 'ERROR', error })
		}
	}

	useEffect(() => {
		dispatch({ type: 'GET_FIREBASE_USER_STARTED' })
		auth.onAuthStateChanged((currentFirebaseUser) => {
			if (currentFirebaseUser) {
				dispatch({ type: 'GET_FIREBASE_USER_FINISHED', currentFirebaseUser })
			} else {
				auth.signInAnonymously()
			}
		})
	}, [auth])

	useEffect(() => {
		if (state.currentFirebaseUser) {
			findOrCreateUser()
		}
	}, [state.currentFirebaseUser])

	return (
		<AuthContext.Provider
			value={{
				...state,
				login,
				signInWithGoogle,
				signInWithEmailAndPassword,
				loginWithGoogle,
				loginWithEmailAndPassword,
				logout,
				findOrCreateUser,
				fetchCurrentUser,
				updateEmail,
				updatePassword,
				resetPassword,
				sendEmailVerification,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider
