import { useContext } from 'react'
import AuthContext, { AuthContextInterface } from './context'

/**
 * ```js
 * const {
 *   // Auth state:
 *   isAuthenticated
 *   currentFirebaseUser,
 *   currentAdmin,
 *   isLoading,
 *   error,
 *   // Auth methods:
 *   login,
 *   signup,
 *   logout
 * } = useAuth()
 * ```
 *
 * Use the `useAuth` hook in your components to access the auth state and methods.
 */
const useAuth = (): AuthContextInterface => useContext(AuthContext)

export default useAuth
