import React from 'react'
import Link from 'next/link'

type Props = {
	itemCount: number
}

export const CartFooter = ({ itemCount }: Props) => {
	return (
		<>
			<div className="fixed bg-white p-4 bottom-0 border-t-2 border-lightGray w-full md:hidden">
				<Link href="/cart">
					<button className="flex items-center justify-between shadow-md rounded-lg bg-orange text-lg text-white font-bold px-6 py-3 w-full">
						<div className="w-6" />
						<div className="flex items-center">
							<div className="mr-2">
								<img className="w-5" src="/cart.svg" alt="cart" />
							</div>
							カートを確認する
						</div>
						<div className="bg-white text-orange w-6 h-6 leading-6 rounded-sm">{itemCount}</div>
					</button>
				</Link>
			</div>
		</>
	)
}
