import firebase from 'firebase'
import { User } from '../../types/user'

export type FirebaseUser = firebase.User
export type FirebaseError = firebase.FirebaseError

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useAuth` hook.
 */
export type AuthState = {
	isAuthenticated: boolean
	currentFirebaseUser?: FirebaseUser
	currentUser?: User
	isLoadingAuth: boolean
	error?: FirebaseError
}

/**
 * The initial auth state.
 */
export const initialAuthState: AuthState = {
	isAuthenticated: false,
	isLoadingAuth: false,
}
