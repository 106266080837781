import axios from 'axios'
import firebase from 'firebase'
import 'firebase/auth'

axios.interceptors.request.use(
	async (config) => {
		const token = await firebase.auth().currentUser?.getIdToken()
		config.headers.Authorization = `Bearer ${token}`
		config.headers.UID = firebase.auth().currentUser?.uid
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

export { axios }
