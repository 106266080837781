import React, { ReactNode, useState, useEffect } from 'react'
import App from 'next/app'
import Link from 'next/link'
import type { AppProps, AppContext } from 'next/app'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/browser'
import * as gtag from '../utils/gtag'
import axios from 'axios'
import basicAuthCheck from '../utils/basicAuthCheck'
import { translate } from '../utils/firebaseError'
import { AuthProvider } from '../context/auth'
import { CartProvider } from '../context/cart'
import { useAuth } from '../context/auth'
import { useCart } from '../context/cart'
import { auth } from '../lib/firebase-client'
import { Header } from '../components/Header'
import { CartFooter } from '../components/CartFooter'
import { Modal } from '../components/Modal'
// import { Loading } from '../components/Loading'
import { Footer } from '../components/Footer'
// import { YearEnd } from '../components/YearEnd'
import { Maintenance } from '../components/Maintenance'

import 'tailwindcss/tailwind.css'
import 'react-datepicker/dist/react-datepicker.css'

const AppContainer = ({ children }: { children: ReactNode }) => {
	const router = useRouter()
	const { currentFirebaseUser, error, sendEmailVerification } = useAuth()
	const { cart, isLoadingCart } = useCart()

	const [maintenance, setMaintenance] = useState(false)

	useEffect(() => {
		const fetchSystemParameterMaintenance = async () => {
			try {
				const res = await axios.get(`${process.env.REST_ENDPOINT}/api/v1/system_parameters/2`)
				setMaintenance(res.data.value === 'true')
			} catch (error) {
				Sentry.captureException(error)
			}
		}

		fetchSystemParameterMaintenance()
	}, [])

	const showFooter = (): boolean => {
		if (
			router.pathname.match(/\/signup/) ||
			router.pathname.match(/\/login/) ||
			router.pathname.match(/\/about/) ||
			router.pathname.match(/\/privacy/) ||
			router.pathname.match(/\/terms/)
		) {
			return true
		}

		return false
	}

	const showCartFooter = (): boolean => {
		if (
			!isLoadingCart &&
			cart &&
			cart.line_items.length > 0 &&
			cart.order_type &&
			(router.pathname === '/' || router.pathname.match(/\/shops/))
		) {
			return true
		} else {
			return false
		}
	}

	if (maintenance) {
		return <Maintenance />
	}

	// return <YearEnd />

	return (
		<>
			{/* {(isLoadingAuth || isLoadingCart) && <Loading />} */}
			<Header />
			<div className="pt-16">{children}</div>
			{showCartFooter() && <CartFooter itemCount={cart?.line_items.length || 0} />}
			{showFooter() && <Footer />}

			<Modal
				isOpen={
					!!currentFirebaseUser &&
					!currentFirebaseUser.isAnonymous &&
					!currentFirebaseUser.emailVerified &&
					router.pathname !== '/mypage/email'
				}
				closeModal={() => console.log('')}
			>
				<div className="text-lg text-center mb-2">確認用のメールを送信しました！</div>
				<div className="mb-4">
					登録したメールアドレスに届いたメールをご確認いただき、メールに記載されたURLをクリックしてナガタクへの登録を完了してください。
				</div>
				<div className="mb-4">メールの確認後、以下の「進む」をクリックしてください。</div>
				<div className="text-center">
					<button
						className="shadow-md rounded-lg bg-orange text-md text-white font-bold w-36 h-12 mx-2 mb-4"
						onClick={() => window.location.reload()}
					>
						進む
					</button>
					<div className="text-center">
						メールが届いていませんか？
						<span className="text-orange">
							<button onClick={() => sendEmailVerification()}>再送する</button>
						</span>
						<div>または</div>
						<div className="text-orange">
							<Link href="/mypage/email">メールアドレスを変更する</Link>
						</div>
					</div>
					{error && <div className="text-red text-md my-4">{translate(error)}</div>}
				</div>
			</Modal>
		</>
	)
}

const MyApp = ({
	Component,
	pageProps /* isMobileView */,
}: AppProps & { isMobileView: boolean }) => {
	const router = useRouter()

	useEffect(() => {
		const handleRouteChange = (url: URL) => {
			gtag.pageview(url)
		}
		router.events.on('routeChangeComplete', handleRouteChange)
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router.events])

	return (
		<AuthProvider auth={auth}>
			<CartProvider>
				<AppContainer>
					<Component {...pageProps} />
				</AppContainer>
			</CartProvider>
		</AuthProvider>
	)
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	const { req, res } = appContext.ctx

	const isMobileView = (
		req && req.headers['user-agent'] ? req.headers['user-agent'] : navigator.userAgent
	).match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)

	if (req && res && process.env.ENABLE_BASIC_AUTH === 'true') {
		await basicAuthCheck(req, res)
	}

	const appProps = await App.getInitialProps(appContext)
	return { ...appProps, isMobileView: Boolean(isMobileView) }
}

export default MyApp
