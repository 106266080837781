import { useContext } from 'react'
import CartContext, { CartContextInterface } from './context'

/**
 * ```js
 * const {
 *   // Cart state:
 *   cart,
 *   isLoading,
 *   error,
 *   // Cart methods:
 *   findOrCreateCart,
 *   setOrderGruopId
 * } = useCart()
 * ```
 *
 * Use the `useCart` hook in your components to access the auth state and methods.
 */
const useCart = (): CartContextInterface => useContext(CartContext)

export default useCart
