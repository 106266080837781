import { Cart } from '../../types/cart'

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useCart` hook.
 */
export type CartState = {
	isLoadingCart: boolean
	cart?: Cart
	orderGroupId?: number
	error?: Error
}

/**
 * The initial auth state.
 */
export const initialCartState: CartState = {
	isLoadingCart: false,
}
