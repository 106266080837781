import React, { useEffect, useReducer } from 'react'
import { axios } from '../../lib/axios-client'
import { useAuth } from '../auth'
import CartContext from './context'
import { reducer } from './reducer'
import { initialCartState } from './state'
import { Cart } from '../../types/cart'

export interface CartProviderOptions {
	children?: React.ReactNode
	[key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const CartProvider = (opts: CartProviderOptions) => {
	const { children } = opts

	const { currentUser } = useAuth()

	const [state, dispatch] = useReducer(reducer, initialCartState)

	const fetchOrCreateCart = async (orderType?: string) => {
		if (!currentUser) {
			return
		}

		dispatch({ type: 'FETCH_CART_STARTED' })
		try {
			let cart: Cart
			const res = await axios.get(`${process.env.REST_ENDPOINT}/api/v1/carts`)

			// 複数のカートを扱えるようにするかも
			if (res.data.length > 0) {
				cart = res.data[0]
			} else {
				const res = await axios.post(`${process.env.REST_ENDPOINT}/api/v1/carts`, {
					order_type: orderType,
				})
				cart = res.data.cart
			}

			dispatch({ type: 'FETCH_CART_FINISHED', cart })
		} catch (error) {
			console.log(error)
			dispatch({ type: 'ERROR', error })
		}
	}

	const setOrderGroupId = (orderGroupId: number) => {
		dispatch({ type: 'SET_ORDER_GROUP_ID', orderGroupId })
	}

	useEffect(() => {
		fetchOrCreateCart()
	}, [currentUser])

	return (
		<CartContext.Provider
			value={{
				...state,
				fetchOrCreateCart,
				setOrderGroupId,
			}}
		>
			{children}
		</CartContext.Provider>
	)
}

export default CartProvider
