import React from 'react'
import ReactModal from 'react-modal'

type Props = {
	isOpen: boolean
	closeModal: () => void
	children: React.ReactNode
}

export const Modal = (props: Props) => {
	const { isOpen, closeModal, children } = props

	const customStyles = {
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			zIndex: 20,
		},
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			borderRadius: '0.5rem',
			border: 'none',
			boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
			width: '300px',
		},
	}

	return (
		<>
			<ReactModal
				isOpen={isOpen}
				onRequestClose={closeModal}
				style={customStyles}
				ariaHideApp={false}
			>
				{children}
			</ReactModal>
		</>
	)
}
